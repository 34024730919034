// noinspection DuplicatedCode

import { useState, useEffect, Suspense } from 'react'
import { Switch } from 'react-router-dom'

import { Preloader } from 'mmfintech-portal-commons'
import { ErrorBoundary } from 'react-error-boundary'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider, useCookies } from 'react-cookie'

import { withTranslation } from 'react-i18next'
import { GlobalContextProvider, isMobileDevice, isValidObject, OtpContext } from 'mmfintech-commons'
import {
  selectCurrentUserStatus,
  useAppInitializerQry,
  useAppSelector,
  useAuthQuery,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'

import {
  ContentWrapper,
  CookieConsent,
  CustomToaster,
  ErrorFallback,
  Header,
  ModalDialog,
  Otp,
  PrivateRoute,
  PublicRoute,
  Sidebar,
  SuspenseFallback
} from './components'

import theme from './theme'
import settings from './settings'
import { nonProtectedRoutes, protectedRoutes } from './routes'
import { pathByStatus } from './utility'

const AppInner = () => {
  useAppInitializerQry(settings.languages)
  const { isLoading: isFetchingAuth } = useAuthQuery()

  const [cookies, setCookie] = useCookies(['cookie.consent'])
  const [cookieConsent, setCookieConsent] = useState(null)

  const userStatus = useAppSelector(selectCurrentUserStatus)
  const queryChallenge = useAppSelector(state => state.challenge)

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  useEffect(() => {
    if (typeof queryChallenge === 'object' && queryChallenge.hasOwnProperty('challengeId')) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <Otp />
      })
    } else {
      modalHide()
    }
  }, [queryChallenge])

  const [modalContent, setModalContent] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const [sidebarContent, setSidebarContent] = useState(null)
  const [sidebarOptions, setSidebarOptions] = useState(null)
  const [sidebarVisible, setSidebarVisible] = useState(false)

  const [otpOnError, setOtpOnError] = useState(null)
  const [otpOnSuccess, setOtpOnSuccess] = useState(null)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
  }

  const modalShow = ({ options, content }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalVisible(true)
  }

  const sidebarRightHide = () => {
    setSidebarVisible(false)
    setSidebarContent(null)
    setSidebarOptions(null)
  }

  const sidebarRightShow = ({ options, content }) => {
    setSidebarContent(content)
    setSidebarOptions(options)
    setSidebarVisible(true)
  }

  const globalContext = { modalHide, modalShow, sidebarRightHide, sidebarRightShow }

  if (isFetchingAuth) return <Preloader />

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider value={{ otpOnSuccess, setOtpOnSuccess, otpOnError, setOtpOnError }}>
            <ContentWrapper>
              <Header />

              <Switch>
                {nonProtectedRoutes.map(({ path, component }, index) => (
                  <PublicRoute key={index} path={path} exact component={component} />
                ))}
                {protectedRoutes.map(({ path, component, redirect }, index) => (
                  <PrivateRoute
                    key={index}
                    path={path}
                    exact
                    component={component}
                    redirect={redirect}
                    invalidSessionRedirect={pathByStatus(userStatus)}
                  />
                ))}
              </Switch>

              {isValidObject(cookieConsent) || isMobileDevice() ? null : <CookieConsent setCookie={setCookie} />}

              <ModalDialog content={modalContent} options={modalOptions} visible={modalVisible} onClose={modalHide} />

              <Sidebar
                content={sidebarContent}
                options={sidebarOptions}
                visible={sidebarVisible}
                onClose={sidebarRightHide}
              />
            </ContentWrapper>

            <CustomToaster />
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const [logError] = useLazyErrorLoggingQuery()

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, componentStack) => {
          return logError({ level: 'ERROR', componentStack, message: err.toString() })
        }}
        onReset={() => {
          window.location.replace('/')
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
