import { useHistory } from 'react-router-dom'

import { tr, fetchLastChunk, formatMoney, formatDate } from 'mmfintech-commons'
import { formatTransactionDescription, getTransactionStatusName, paths, useCurrencies } from 'mmfintech-backend-api'

import { DataTable } from 'mmfintech-portal-commons'
import { StatusBanner } from '../../../components'
import { TransactionsTableContainer, TransactionsWrapper } from './TransactionsTable.styled'

import { TransactionViewPortal } from 'mmfintech-commons-types'

export const TransactionsTable = ({ transactions }) => {
  const history = useHistory()

  const { getCurrencyPrecision } = useCurrencies()

  const handleDetailsClick = (transactionId: number): void => {
    history.push(paths.banking.transactions.details(transactionId))
  }

  return (
    <TransactionsWrapper>
      <TransactionsTableContainer>
        <DataTable.Table scale1={'1000px'} scale2={'1000px'} skipHover={true}>
          <DataTable.Head>
            <DataTable.Row>
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}</DataTable.HeadCell>
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}</DataTable.HeadCell>
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Provided ID')}</DataTable.HeadCell>
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}</DataTable.HeadCell>
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}</DataTable.HeadCell>
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}</DataTable.HeadCell>
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}</DataTable.HeadCell>
            </DataTable.Row>
          </DataTable.Head>

          <DataTable.Body>
            {transactions.map((data: TransactionViewPortal) => {
              const {
                id,
                reqTime,
                amount: transactionAmount,
                currency: transactionCurrency,
                direction,
                status,
                trnTypeLocalizationKey,
                foreignTransactionId,
                cardPaymentDetails
              } = data
              const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } =
                cardPaymentDetails || {}

              const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
              const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

              return (
                <DataTable.Row key={`transaction-${id}`} data-test='table-row' onClick={() => handleDetailsClick(id)}>
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')} data-test='id'>
                    {id}
                  </DataTable.Cell>

                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                    className='no-wrap'
                    data-test='date'>
                    {formatDate(reqTime)}
                  </DataTable.Cell>
                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Provided ID')}>
                    {foreignTransactionId}
                  </DataTable.Cell>

                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')} data-test='type'>
                    {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                  </DataTable.Cell>

                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                    data-test='description'>
                    {formatTransactionDescription(data)}
                  </DataTable.Cell>

                  <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')} data-test='amount'>
                    {formatMoney(
                      amount * (direction === 'WITHDRAW' ? -1 : 1) || 0,
                      currency || '',
                      getCurrencyPrecision(currency)
                    )}
                  </DataTable.Cell>

                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                    className={'text-upper status-' + status.toLowerCase()}
                    data-test='status'>
                    <StatusBanner status={getTransactionStatusName(status)} />
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable.Body>
        </DataTable.Table>
      </TransactionsTableContainer>
    </TransactionsWrapper>
  )
}
